import { FC } from 'react';
import { graphql } from 'gatsby';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleHeading } from '../../components/organisms/ArticleHeading';
import { NewsTemplate } from '../../components/news/NewsTemplate';

type HeadProps = {
  data: GatsbyTypes.InformationTemplateQueryQuery;
};

export const Head: FC<HeadProps> = (props) => {
  const information = props.data.contentfulInformation;
  if (!information) return null;

  const coverFile = information?.cover?.file;
  return (
    <HeadInner
      path={`/info/${information.slug}`}
      title={information.title}
      description={information?.description?.description}
      imageUrl={coverFile?.url ? `https:${coverFile.url}` : undefined}
      width={coverFile?.details?.image?.width}
      height={coverFile?.details?.image?.height}
    />
  );
};

const InformationTemplate: FC<{
  data: GatsbyTypes.InformationTemplateQueryQuery;
}> = (props) => {
  const information = props.data.contentfulInformation;
  if (!information) return null;
  // 日付を切り出し
  const date = `${information.publishedAt?.slice(
    0,
    4
  )}.${information.publishedAt?.slice(5, 7)}.${information.publishedAt?.slice(
    8,
    10
  )}`;

  const links = [
    { name: 'お知らせ', path: '/news/' },
    {
      name: `${information.title}`,
      path: `/info/${information.slug}`,
    },
  ];

  return (
    <Layout links={links}>
      <ArticleHeading category='news' title={information.title ?? ''} />
      <NewsTemplate
        newsCategory='info'
        publishAt={`${date}`}
        content={information.content?.childMdx?.body!}
      />
    </Layout>
  );
};

export const query = graphql`
  query InformationTemplateQuery($id: String) {
    contentfulInformation(id: { eq: $id }) {
      id
      slug
      title
      publishedAt
      description {
        description
      }
      content {
        content
        childMdx {
          body
        }
      }
      cover {
        gatsbyImageData
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      metadata {
        tags {
          name
        }
      }
    }
  }
`;

export default InformationTemplate;
