import { css } from '@emotion/react';
import { FC } from 'react';
import { colors } from '../../theme/colors';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { PaildMdxProvider } from '../PaildMdxProvider';

type Props = {
  newsCategory: 'info' | 'pressRelease' | 'serviceUpdate';
  publishAt: string;
  content: string;
};

export const NewsTemplate: FC<Props> = ({
  newsCategory,
  publishAt,
  content,
}) => {
  const switchNewsCategory = () => {
    switch (newsCategory) {
      case 'info':
        return 'お知らせ';
      case 'pressRelease':
        return 'プレスリリース';
      case 'serviceUpdate':
        return 'アップデート';
    }
  };

  return (
    <div css={NewsTemplateStyle}>
      <div css={NewsInfoWrapperStyle}>
        <div css={CategoryTagStyle}>{switchNewsCategory()}</div>
        <div>{publishAt}</div>
      </div>
      <PaildMdxProvider>
        <MDXRenderer>{content}</MDXRenderer>
      </PaildMdxProvider>
    </div>
  );
};
const NewsTemplateStyle = css({
  width: '100%',
  maxWidth: 1120,
  color: colors.black,

  display: 'flex',
  flexDirection: 'column',
  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    padding: 14,
    gap: 50,
  },

  '@media(max-width: 768px)': {
    gap: 30,
  },
});

const NewsInfoWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  color: colors.gray,
});

const CategoryTagStyle = css({
  width: 'fit-content',
  fontSize: 12,
  border: `2px solid ${colors.primary}`,
  padding: '6px 10px',
  borderRadius: 24,
  color: colors.primary,
  lineHeight: 1,
});
